@charset "UTF-8";
@import "~dompack/browserfix/reset.css";
@import "~sidebarjs/dist/sidebarjs.css";
.bg-primary {
  background-color: #bd022a !important;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("@mod-schippers/webdesigns/lifestyleshop/node_modules/slick-carousel/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("@mod-schippers/webdesigns/lifestyleshop/node_modules/slick-carousel/slick/fonts/slick.eot");
  src: url("@mod-schippers/webdesigns/lifestyleshop/node_modules/slick-carousel/slick/fonts/slick.eot?#iefix") format("embedded-opentype"), url("@mod-schippers/webdesigns/lifestyleshop/node_modules/slick-carousel/slick/fonts/slick.woff") format("woff"), url("@mod-schippers/webdesigns/lifestyleshop/node_modules/slick-carousel/slick/fonts/slick.ttf") format("truetype"), url("@mod-schippers/webdesigns/lifestyleshop/node_modules/slick-carousel/slick/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

html {
  background-color: #fff;
  scroll-behavior: smooth;
}

body {
  background: #fff;
}

.wh-anchor,
#productsgrid_anchor,
#product__related__anchor {
  scroll-margin-top: 150px;
  top: -150px;
  position: absolute;
}

.main--margintop,
.page--rtd main,
.page--search main {
  margin-top: 1.5rem;
}

.site__content {
  flex: 1 1 auto;
}

.breadcrumb {
  background: none;
  padding-left: 0;
  margin-bottom: 0.2rem;
  font-size: 13px;
  padding-top: 5px;
}

#product_listing .slick-slide img {
  display: initial;
}

main > nav {
  margin-top: -10px;
}

main > *:last-child {
  margin-bottom: 50px;
}

main .pageimage {
  margin: 0 -20px 20px;
}
main .pageimage > img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.contents--spaceright {
  padding-right: 30%; /* 825 */
  position: relative;
}

.contents__imageright {
  width: calc(30% - 20px);
  min-height: 20px;
  position: absolute;
  top: 7px;
  right: 0;
}
.contents__imageright > img {
  width: 100%;
  height: auto;
}

.webshop-checkout,
html:not(.webshop--iscategorypage) main > * {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 767px) {
  .contents--spaceright {
    padding-right: 0;
  }
  .contents__imageright {
    display: none;
  }
}
.pagetags {
  margin: 30px 0;
}
.pagetags ul {
  list-style-type: none;
  margin: 0 0 0 -6px;
  padding: 0;
}
.pagetags ul li {
  display: inline-block;
  margin-bottom: 6px;
  margin-left: 6px;
}
.pagetags ul li a {
  display: inline-block;
  color: #b5aba7;
  border-color: #b5aba7;
  padding: 6px 12px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  transition: background-color 0.2s, border-color 0.2s;
  border: 1px solid transparent;
}
.pagetags ul li a:hover {
  background-color: #fff;
  border-color: #584a45;
}

.breadcrumb {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumb li {
  display: inline-block;
}

.breadcrumb a {
  color: #ccc;
}

.breadcrumb-item + .breadcrumb-item:before {
  color: #ccc;
}

.contentarea {
  position: relative;
  z-index: 0;
}

button {
  cursor: pointer;
}

.btn {
  cursor: pointer;
  border-radius: 0;
}
.btn-primary {
  background-color: #bd022a;
  border-color: #bd022a;
  color: #fff !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.btn-primary:hover, .btn-primary:focus {
  background-color: #d60230;
  border-color: #d60230;
  text-decoration: none;
  color: #fff;
}
.btn-primary:active, .btn-primary.active, .btn-primary.active:hover {
  background-color: #5dbfec;
  border-color: #3498c7;
}
.btn-secondary {
  background-color: #000000;
  border-color: #000000;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.btn-secondary:hover, .btn-secondary:focus {
  background-color: #222222;
  border-color: #222222;
  text-decoration: none;
}
.btn-secondary:active, .btn-secondary.active, .btn-secondary.active:hover {
  background-color: #5dbfec;
  border-color: #3498c7;
}
.btn-outline-light:hover, .btn-outline-light:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.card {
  border-radius: 0;
}

.card-body {
  padding: 1rem;
}

aside {
  margin: 0 0 20px;
}
aside.widget-login {
  background: #fff;
  margin: 0 0 20px;
  padding: 20px;
  overflow: hidden;
}
aside .aside__title {
  font-size: 21px;
  color: #000;
  font-weight: 400;
  font-family: adobe-garamond-pro, serif;
  line-height: 1.1;
  display: block;
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 1.1;
}

html.webshop--ischeckoutpage .site__content {
  margin-top: 30px;
}

.block {
  background: #fff;
  margin: 0 0 20px;
  padding: 30px;
  position: relative;
}

aside .mm-rating,
.reviewtotals .mm-rating {
  display: block;
  font-size: 21px;
  margin: 7px 0 -8px -2px;
  font-weight: 600;
}

aside .mm-recensie,
.reviewtotals .mm-recensie {
  background: #000000;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  width: 50px;
  height: 50px;
  float: left;
  margin-right: 5px;
}

aside .mm-rating-txt,
aside .mm-rating-txt a,
.reviewtotals .mm-rating-txt,
.reviewtotals .mm-rating-txt a {
  display: inline-block;
  font-size: 14px;
  margin: 0;
}

.mydialog {
  background: white;
  padding: 20px;
}
.mydialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mydialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.mydialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

#sidenav {
  /*
    & li > ul li.active > a
    {
      color: #584a45;
    }
  */
}
#sidenav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#sidenav > ul {
  margin-top: -5px;
}
#sidenav li {
  display: block;
}
#sidenav a {
  display: block;
  text-decoration: none;
  font-size: 13px;
  line-height: 150%;
}
#sidenav a:hover {
  text-decoration: underline;
}
#sidenav > ul > li > a {
  font-weight: 400;
  font-size: 21px;
  color: #584a45;
}
#sidenav > ul > li > ul > li li {
  padding-left: 15px;
}
#sidenav li > ul a {
  padding: 5px 0 3px 15px;
  position: relative;
}
#sidenav li > ul a:before {
  content: "\f105";
  display: inline-block;
  position: absolute;
  font-family: "Line Awesome Free";
  font-weight: 900;
  color: inherit;
  font-size: 16px;
  top: 5px;
  left: 0;
}

.contents__catpath {
  list-style: none;
  margin-bottom: 10px;
  padding-left: 0;
  color: #a89895;
}

.contents__catpathelt {
  display: inline-block;
}

.contents__catpathelt + li::before {
  padding: 0 5px;
  color: #ccc;
  content: "/ ";
}

.summary .pagedescription {
  font-size: 17px;
  font-weight: 400;
  padding-bottom: 30px;
}
.summary ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.summary ul:after {
  clear: both;
  content: "";
  display: block;
}
.summary ul li {
  width: 50%;
  float: left;
  padding-right: 10px;
  padding-bottom: 20px;
}
.summary ul li:nth-child(2n) {
  padding-left: 10px;
  padding-right: 0;
}
@media (max-width: 500px) {
  .summary ul li {
    width: 100%;
    float: none;
    padding-right: 0;
  }
  .summary ul li:nth-child(2n) {
    padding-left: 0;
  }
}
.summary ul li .image > img {
  vertical-align: bottom;
  width: 100%;
  height: auto;
}
.summary ul li .title {
  padding: 15px;
  font-size: 20px;
  font-weight: normal;
  line-height: 120%;
}
.summary ul li .description {
  padding: 15px;
  background-color: #f5f5f5;
}
.summary ul .button {
  margin-top: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 30px;
  display: inline-block;
  line-height: 28px;
  position: relative;
  padding: 0 10px 0 25px;
  white-space: nowrap;
  background-color: #fff;
}
.summary ul .button > span {
  position: absolute;
  top: 8px;
  left: 10px;
}
.summary ul a {
  display: block;
  text-decoration: none;
  color: inherit;
  border: 1px solid #f5f5f5;
}
.summary ul a > * {
  display: block;
}
.summary ul a:hover .button {
  background-color: #e6e6e6;
}

.webshop-account__orders {
  width: 100%;
  margin: 0 0 20px;
  border-bottom: 1px solid #f0f0f0;
}
.webshop-account__orders .webshop-account__ordertotal:before {
  display: inline-block;
  content: "€";
  padding-right: 5px;
}
.webshop-account__orders td, .webshop-account__orders th {
  padding: 5px 20px 5px 0;
  white-space: nowrap;
}
.webshop-account__orders .webshop-account__ordertotal {
  text-align: right;
  width: 100px;
}
.webshop-account__orders .webshop-account__orderrow:nth-child(2n-1) td {
  background-color: #fafafa;
}

.webshop-account__fullbillingaddress .webshop-account__ordertotal:before {
  content: "€ ";
}

.btn-default {
  color: #999;
  border-color: #ccc;
  transition: all 0.5s;
}

.btn-default.btn-outline:hover {
  background-color: #d9534f;
  color: #fff;
  border-color: #d9534f;
}

.submenu:not(.d-none) {
  display: none;
}

/* mobile left sidemenu: */
#mainSideNav,
#filterSideNav {
  visibility: hidden;
  border-top: 0;
}
#mainSideNav .close-btn,
#filterSideNav .close-btn {
  width: 42px;
  height: 42px;
  display: block;
  float: right;
  width: 42px;
  height: 41px;
  display: block;
  padding: 5px 5px;
}
#mainSideNav .close-btn:before,
#filterSideNav .close-btn:before {
  content: "";
  width: 15px;
  margin: 50% auto 0 auto;
  height: 2px;
  background: #000;
  display: block;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  transform: translateY(-1px) rotate(45deg);
}
#mainSideNav .close-btn:after,
#filterSideNav .close-btn:after {
  content: "";
  width: 15px;
  margin: 0 auto;
  height: 2px;
  margin-top: -2px;
  background: #000;
  display: block;
  -webkit-transform: translateY(-1px) rotate(-90deg);
  transform: translateY(-1px) rotate(-45deg);
  -webkit-transition: all 300ms;
  transition: all 300ms;
}
#mainSideNav ul,
#filterSideNav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#mainSideNav ul.left-side-menu > li:last-child a,
#filterSideNav ul.left-side-menu > li:last-child a {
  color: #bd022a;
}
#mainSideNav ul .list-inline-item:not(:last-child),
#filterSideNav ul .list-inline-item:not(:last-child) {
  margin-right: 0;
}
#mainSideNav [sidebarjs-container],
#mainSideNav [sidebarjs-backdrop],
#filterSideNav [sidebarjs-container],
#filterSideNav [sidebarjs-backdrop] {
  transition: all 0.3s ease;
}
#mainSideNav [sidebarjs-container],
#filterSideNav [sidebarjs-container] {
  background: #fff;
  overflow-y: auto;
}
#mainSideNav [sidebarjs-container] .nav-link,
#filterSideNav [sidebarjs-container] .nav-link {
  font-size: 16px;
}
#mainSideNav [sidebarjs-container] a,
#mainSideNav [sidebarjs-container] h3,
#filterSideNav [sidebarjs-container] a,
#filterSideNav [sidebarjs-container] h3 {
  color: #000;
}
#mainSideNav.sidebarjs--is-visible,
#filterSideNav.sidebarjs--is-visible {
  visibility: visible;
}
#mainSideNav .has-submenu,
#filterSideNav .has-submenu {
  position: relative;
}
#mainSideNav .has-submenu .nav-link,
#filterSideNav .has-submenu .nav-link {
  display: block;
  margin-right: 42px;
  font-size: 16px;
}
#mainSideNav .has-submenu > .submenu-toggle,
#filterSideNav .has-submenu > .submenu-toggle {
  position: absolute;
  right: 0px;
  top: -40px;
  z-index: 1;
  display: block;
  float: right;
  position: relative;
  width: 40px;
  height: 41px;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
#mainSideNav .has-submenu > .submenu-toggle:last-child,
#filterSideNav .has-submenu > .submenu-toggle:last-child {
  border-top: none;
}
#mainSideNav .has-submenu > .submenu-toggle:before,
#filterSideNav .has-submenu > .submenu-toggle:before {
  content: "";
  position: absolute;
  width: 12px;
  top: 20px;
  left: 15px;
  height: 2px;
  background: #000;
  display: inline-block;
}
#mainSideNav .has-submenu > .submenu-toggle:after,
#filterSideNav .has-submenu > .submenu-toggle:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 20px;
  width: 2px;
  height: 12px;
  background: #000;
  display: inline-block;
}
#mainSideNav .has-submenu > .submenu-toggle:hover,
#filterSideNav .has-submenu > .submenu-toggle:hover {
  cursor: pointer;
}
#mainSideNav .has-submenu > .submenu-toggle.collapse--open:before,
#filterSideNav .has-submenu > .submenu-toggle.collapse--open:before {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#mainSideNav .has-submenu > .submenu-toggle.collapse--open:after,
#filterSideNav .has-submenu > .submenu-toggle.collapse--open:after {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  top: 20px;
  width: 2px;
  height: 2px;
}
#mainSideNav ul li,
#filterSideNav ul li {
  border-top: 1px solid #bfbfbf;
  margin-top: 0;
}
#mainSideNav ul li ul li,
#filterSideNav ul li ul li {
  border: none;
}
#mainSideNav ul.active li,
#filterSideNav ul.active li {
  border: none;
}
#mainSideNav ul li.active,
#filterSideNav ul li.active {
  border-top: 0;
}
#mainSideNav ul.submenu,
#filterSideNav ul.submenu {
  margin-left: 0.5rem;
}
#mainSideNav .btn-link,
#mainSideNav .btn-link:active,
#mainSideNav .btn-link:focus,
#mainSideNav .btn-link:hover,
#filterSideNav .btn-link,
#filterSideNav .btn-link:active,
#filterSideNav .btn-link:focus,
#filterSideNav .btn-link:hover {
  border-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 5px;
}

#filterSideNav ul li {
  border-top: 0 solid #bfbfbf;
}

@media (min-width: 1200px) {
  #mainSideNav {
    display: none;
  }
}
.z-depth-1 {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

html.ls--verlanglijstje main {
  margin-top: 1.5rem;
}

.productsgrid__product {
  opacity: 1;
  transition: opacity 300ms;
}

.productsgrid__product--fadeout {
  opacity: 0;
}

#custom-notification {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  background: #bd022a;
  color: #fff;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000000;
  height: 0;
  position: relative;
  overflow: hidden;
  max-height: 100vh;
}
#custom-notification .container {
  padding: 10px 0;
}
#custom-notification .container p {
  margin-bottom: 0;
  color: #fff;
}
#custom-notification a {
  text-decoration: underline;
  color: #fff;
  display: inline-block;
  margin-left: 5px;
}
#custom-notification button {
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 5px;
  color: #fff;
}