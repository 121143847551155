.widget
{
  margin-bottom: 1rem;
}
  .widget--mobile
, .widget--desktop
{
  display: none;
}
@media(max-width: 767px)
{
  .widget--mobile
  {
    display: block;
  }
}
@media(min-width: 768px)
{
  .widget--desktop
  {
    display: block;
  }
}
aside
{
  border-bottom:1px solid #ddd;
  padding-bottom: 20px;
}

.widget-categoryusps
{
    border-top: 1px solid #ddd;
    padding-top: 25px;
    margin-top: 40px;
}

/* productfilter(s) */
.btn-showfilter
{
  cursor: pointer;
}
.productsgrid[data-filterconfig="null"] .widget-mobileproductfilter
{
  display:none;
}
.widget-productfilter
{
  padding-bottom: 5px;
}
#filterSideNav .filtertype--checkbox::-webkit-scrollbar
{
    -webkit-appearance: none;
    width: 7px;
}
#filterSideNav .filtertype--checkbox::-webkit-scrollbar-thumb
{
    background-color: #574a44;
    border-radius: 0;
    border: 0 solid #fff;
}
#filterSideNav .filtertype--checkbox::-webkit-scrollbar-track
{
    border-radius: 0;
    background-color: #706059;
}
.productfilter__activetitle
{
  margin-bottom: 5px;
}
.productfilter__activelabel
{
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 10px;
  font-style: italic;
  font-size: 13px;
  margin-bottom: 3px;
  color:#a89895;
}
.productfilter__activeremove:before
{
  content: "x";
  float: right;
  color:red;
  font-size: 16px;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
}

.productfilter .filtertype--checkbox,
.productfilter .filtertype--select
{
    max-height: 215px;
    overflow-x: hidden;
    overflow-y: auto;
}


.productfilter:last-child
{
  padding-bottom: 0;
}
.productfilter-title
{
  padding: 10px 0;
  display: block;
  font-size: 18px;
  font-weight: 600;
  color:#000;
}
.productfilter-title[data-toggle="collapse"]:after
{
  content: "\f106";
  font-family: 'Line Awesome Free';
  font-weight: 900;
  float: right;
  font-size: 14px;
}

.productfilter-title[data-toggle="collapse"].collapsed:after
{
  content: "\f107";
  font-family: 'Line Awesome Free';
  font-weight: 900;
  float: right;
  font-size: 14px;
}

.productfilter--optionlist
{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.productfilter--optionlist li
{
  display: flex;
  align-content: flex-start;
}
.productfilter--optionlist li input
{
  margin-top: 3px;
}
.productfilter--optionlist li label
{
  padding-left: 5px;
  margin-bottom: 0;
}
.productfilter--select
{
  width: 100%;
  padding: 5px;
}


#filterSideNav .widget-productfilter .aside__title
{
  color:#000;
  font-weight: 400;
}
#filterSideNav .productfilter__activelabel
{
  background-color: #eee;
  color:#000;
  border:none;
}
#filterSideNav .productfilter + .productfilter
{
  border-top:0;
}

#filterSideNav .widget-productfilter
{
  border-bottom:0;
}
#filterSidenav .wh-slider-holder>.wh-slider
{
  border:none;
}
#filterSideNav .productfilter-title,
#filterSideNav .productfilter--optionlist li label,
#filterSideNav .productfilter__activetitle,
#filterSideNav .wh-slider-holder > .wh-slider > .wh-slider-knob
{
  color: #000;
  font-size: 16px;
}
#filterSideNav ul li
{
  border-bottom:0!important;
}
#filterSideNav .productfilter--select
{
  border:none;
}
#filterSideNav .productfilter__activeremove:before
{
  color:#bd022a;
}
#filterSideNav  .productfilter--optionlist li label
{
    margin-bottom: 5px;
}
#product_listing .webshop-products--ordering-container .form-control
{
    width: auto;
    display: inline-block;
}
.webshop-products--ordering-container
{
  margin:25px 0;
}
/**/
.widget-fullwidthimage a
{
  display: block;
  text-decoration: none;
}
.widget-fullwidthimage img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

/**/
.widget-categorieslist
{
  list-style-type: none;
  margin: 10px 0 20px;
  padding: 0;
}
.widget-categorieslist li
{
  border: 1px solid #ddd;
  display: block;
  background-color: #fff;
  transition: background-color 0.2s;
}
.widget-categorieslist li + li
{
  margin-top: -1px;
}
.widget-categorieslist li:hover
{
  background-color: #f5f5f5;
}
.widget-categorieslist li:first-child
{
  border-radius: 4px 4px 0 0;
}
.widget-categorieslist li:last-child
{
  border-radius: 0 0 4px 4px;
}
.widget-categorieslist a
{
  display: block;
  color: #555;
  position: relative;
  padding: 10px 15px;
}
.widget-categorieslist a:after
{
  font-family: 'Line Awesome Free';
  font-weight: 900;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -10px;
  content: "\f105";
  font-size: 16px;
}
.widget-categorieslist a:hover
{
  text-decoration: none;
}

/* contact*/
.widget-contact h4
{
  font-size: 18px;
  font-weight: 600;
}
.widget-contact ul
{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-contact li
{
  border-bottom: 1px solid #ccc;
  padding: 8px 0;
}
  .widget-contact li > a
, .widget-contact li > .jivo-btn
{
  display: block;
  position: relative;
  padding-left: 27px;
  color: #337ab7;
  cursor: pointer;
}
  .widget-contact li > a:hover
, .widget-contact li > .jivo-btn:hover
{
  text-decoration: underline;
}
.widget-contact .fa
{
  position: absolute;
  left: 0;
  right: 0;
  font-size: 19px;
  color: #4dadd8;
}

/* banner */
.widget-image__link
{
  color: #584a45;
}
.widget-image__link:hover
{
  color: inherit;
}
.widget-image img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.widget-innerlinks a
{
  color: #000;
  font-weight: normal;
  font-size:14px;
}
.widget-innerlinks .badge-light:hover,
.widget-innerlinks .badge-light:focus
{
  background-color: #fff;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

/* group for quicklink widgets */
.widget-widgetgroup
{
  display: flex;
  flex-flow: wrap;
  list-style-type: none;
  margin: 0 0 0 -30px;
  padding: 0;
}
.widget-widgetgroup > li
{
  flex: 1 1 50%;
  padding: 0 15px 20px 0px;
}
.widget-widgetgroup > li > .widget
{
  height: 100%;
  margin-bottom: 0;
}
@media( max-width: 600px )
{
  .widget-widgetgroup
  {
    display: block;
    margin-left: 0;
  }
  .widget-widgetgroup > li
  {
    flex: none;
    width: 100%;
    padding-left: 0;
  }
}

.categorylist__list,
.subcategorylist__list
{
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.subcategorylist__list
{
  display: none;
}
.subcategorylist__listitem
{
  font-size: 15px;
}
.categorylist__listitem
{
  list-style-type: none;
  padding: 2px 0;
}
.subcategorylist__listitem
{
  list-style-type: none;
  padding: 2px 0px 0 10px;
}
li.selected > .subcategorylist__list
{
  display: block;
}
li.selected > .subcategorylist__listitemlink {
  font-weight: 400;
  text-decoration: underline;
}
.categorylist__listitemlink,
.subcategorylist__listitemlink
{
  color: #000;
}
.categorygrid > a
{
  width: 100%;
}
.overflow {
    position: relative;
    overflow: hidden;
}
.zoom img {
    transition: all 0.2s linear;
}
.zoom:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.widget-categoriebanners
{
  padding:50px 0;
}
.widget-categoriebanners a
{
  display: block;
}
.widget-categoriebanners h2
{
  margin-bottom: 25px;
}
.widget-categoriebanners i
{
  font-size: 21px;
}
.widget-categoriebanners div[class*=col-]
{
  position: relative;
}

.widget-categoriebanners .pr-1
{
  padding-right: 2px!important;
}
.widget-categoriebanners .pb-1
{
  padding-bottom: 2px!important;
}

.widget-categoriebanners span
{
  position: absolute;
  bottom: 24px;
  left: 37px;
  color: #fff;
  font-size: 27px;
  font-weight: 600;
}

@media( max-width: 600px )
{
  .widget-categoriebanners span
  {
    font-size: 14px;
    bottom: 4px;
    left: 25px;
  }
  .widget-categoriebanners i
  {
      font-size: 11px;
  }
}

.instagram_feed
{
  display: table;
}
.instagram_profile
{
  text-align: center;
  width: 34%;
  padding:12% 20px 0;
  display: table-cell;
  vertical-align: middle;
  background: url(../web/img/instagram-widget-bg.gif) no-repeat 0px -66px  #f0f0f0;
  background-size: contain;
}
.instagram_profile img
{
  border-radius: 10em;
  width: 15%;
  max-width: 125px;
  min-width: 125px;
  margin-bottom:10px;
  border: 2px solid #f0f0f0;
}
.instagram_username
{
  color:transparent;
  text-align: center;
  margin-bottom:0;
  font-size: 0;
  line-height: 0;
}
.instagram_username:before
{
  content: "";
  width: 100%;
  height: 50px;
  display: block;
  background-size: contain;
  background-position: top center;
  background-image: url(../web/img/Instagram_logo.png);
  background-repeat: no-repeat;
}
.instagram_username a
{
  color:#000;
  display: block;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
}
.instagram_biography
{
  font-size: 18px;
}
.instagram_gallery
{
  width: 66%;
  display: table-cell;
}
.instagram_gallery img
{
  border:1px solid #fff;
  width: 25%;
  float: left;
}

@media (max-width: 767px)
{
  .instagram_profile
  {
    width: 100%;
    display: block;
/*    max-width: 300px;
*/  padding: 52% 10px 1px 10px;
    margin: 0 auto;
  }
  .instagram_gallery
  {
    width: 100%;
    display: block;
  }
}

@media (max-width: 400px)
{
  .instagram_profile
  {
    padding: 42% 20px 1px 20px;
  }
  .instagram_gallery img
  {
    width: 33.33%;
  }
}


.products-slider
{
  padding:50px 0;
}
.widget-slideshowthumbnails.slider-nav
{
  position: relative;
  margin:-50px 10px 0 10px;
}
.slick-slide
{
  margin:0 10px;
}
.logo__carousel
{
  margin: 30px 0 0 0;
  padding: 30px 0 130px 0;
}
.logo-slider .slick-track
{
  display: flex;
  align-items: center;
}
.logo-slider .slick-track .slick-slide
{
  margin-right: 20px;
}
.logo-slider .slick-track .slick-slide > div
{
  width: fit-content;
  margin:0 auto;
}
.logo-slider .slick-track .slick-slide img
{
  max-height: 100px;
  width: auto!important;
}

.widget-slideshowthumbnails.slider-nav img
{
  border:2px solid rgba(255,255,255,1);
}
.widget-slideshowthumbnails .slick-prev
{
  left: 25px;
}
.widget-slideshowthumbnails .slick-next
{
  right: 25px;
}
.widget-tagcloud ul
{
    list-style: none;
    padding: 0;
    margin: 0 -7.5px 0;
    overflow: hidden;
}
.widget-tagcloud li
{
    display: table-row;
}
.widget-tagcloud li:before
{
    content: "\f105";
    width: 2em;
    font-weight: 900;
    font-size: 13px;
    line-height: 21px;
    font-family: 'Line Awesome Free';
    display: table-cell;
    padding-left: 7px;
    color: #000;
}
.widget-tagcloud li a,
.widget-tagcloud .btn-link
{
  color:#000;
}
/* ========================================================================

   Verlanglijstje

*/

/* toon verlanglijstje verwijdering alleen op verlanglijst pagina */
html:not(.ls--verlanglijstje) .ls--only-verlanglijstje
{
  display:none;
}
.ls__removefromlist label
{
  cursor:pointer;
}
.ls__removefromlist .webshop-product__favorite
{
  visibility: hidden;
  width:0;
}
.ls__removefromlist__text
{
  margin-left:5px;
}
