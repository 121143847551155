@charset "UTF-8";
.bg-primary {
  background-color: #bd022a !important;
}

html.webshop--iscategorypage body,
html.webshop--istagpage body {
  background-color: #f9f9f9;
}
html.webshop--iscategorypage body main,
html.webshop--istagpage body main {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
@media (max-width: 565px) {
  html.webshop--iscategorypage body,
  html.webshop--istagpage body {
    background-color: #fff;
  }
  html.webshop--iscategorypage body main,
  html.webshop--istagpage body main {
    padding: 0;
    box-shadow: none !important;
  }
}

@media (min-width: 768px) {
  html.page--fullwidth #product_listing .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

#product_listing {
  margin-bottom: 50px;
}
#product_listing .productsgrid__product--hidden {
  display: none;
}

.productsgrid_anchor {
  position: absolute;
  top: -100px;
  left: 0;
}

.page--categories .breadcrumb .widget-fullwidthimage img {
  border-radius: 0.25rem;
}

.product__related .productsgrid .productitem__baseprice > .price,
.combinesection__products .productsgrid .productitem__baseprice > .price {
  font-size: 16px;
}
.product__related .productsgrid .productitem__nowprice > span,
.combinesection__products .productsgrid .productitem__nowprice > span {
  font-size: 21px;
}

.card-columns .card {
  margin-bottom: 1.75rem;
}

.productsgrid a, .productsgrid a p, .productsgrid a:hover p,
.categorygrid a,
.categorygrid a p,
.categorygrid a:hover p {
  color: #58595b;
}
.productsgrid a:hover,
.categorygrid a:hover {
  text-decoration: none;
}

.categorygrid:empty {
  display: none;
}

.productsgrid__product .productitem {
  font: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  border: none;
  text-align: center;
}
.productsgrid__product .productitem .badge-danger {
  background-color: #d13539;
  position: absolute;
  right: 0;
  max-width: 42px;
  padding: 5px 6px;
  border-radius: 3px;
}
.productsgrid__product .productitem .las {
  font-size: 18px;
  position: relative;
  top: 1px;
}
.productsgrid__product .productitem .btn {
  opacity: 1;
  transition: opacity 0.2s ease-in;
  -webkit-transition: opacity 0.2s ease-in;
}
.productsgrid__product .productitem:hover, .productsgrid__product .productitem:focus {
  text-decoration: none;
  color: inherit;
}
.productsgrid__product .productitem:hover .btn, .productsgrid__product .productitem:focus .btn {
  opacity: 1;
}
.productsgrid__product .productitem.ribbon:before {
  width: 124px;
  height: 124px;
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
}
.productsgrid__product .productitem .productitem__image {
  text-align: center;
  margin-bottom: 10px;
  min-height: 160px;
}
@media (max-width: 576px) {
  .productsgrid__product .productitem .productitem__image {
    min-height: 100px;
  }
}
.productsgrid__product .productitem .card-body {
  padding: 0;
}
.productsgrid__product .productitem .card-body .productitem__description {
  font-size: 14px;
  line-height: 19px;
  padding: 0 10px;
}
.productsgrid__product .productitem .card-body .productitem__description ul.checkmarks {
  margin-bottom: 0;
}
.productsgrid__product .productitem .card-body .productitem__description ul.checkmarks li:before {
  content: "●";
  font-size: 10px;
  line-height: 23px;
  top: -2px;
  color: #58595b;
  position: relative;
}
.productsgrid__product .productitem .card-body .productitem__description p {
  margin-bottom: 0;
}
.productsgrid__product .productitem .card-footer {
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  border-top: 0;
}
.productsgrid__product .productitem__title {
  font-size: 17px;
}
.productsgrid__product .productitem__discountslogan {
  color: #4aa814;
  font-weight: 600;
  font-size: 14px;
}
.productsgrid__product .productitem__baseprice {
  display: inline-block;
}
.productsgrid__product .productitem__price {
  align-content: center;
}
.productsgrid__product .productitem__baseprice > .price {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  color: #929292;
  margin-right: 5px;
}
.productsgrid__product .productitem__baseprice > .price:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0px;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 1px solid #929292;
  border-radius: 0;
  transform: rotate(-11deg);
}
.productsgrid__product .productitem__baseprice + .productitem__nowprice {
  padding-top: 2px;
  display: inline-block;
}
.productsgrid__product .productitem__nowprice > span {
  display: inline-block;
  font-size: 21px;
  font-weight: 600;
  color: #000;
}
@media (max-width: 565px) {
  .productsgrid__product .productitem__nowprice > span {
    margin-bottom: 10px;
  }
}
.productsgrid__product .productitem__baseprice > span.pricemultiple, .productsgrid__product .productitem__nowprice > span.pricemultiple {
  font-size: inherit;
  font-weight: inherit;
}
.productsgrid__product .productitem .btn {
  font-size: 16px;
}
.productsgrid__product .productitem__message {
  color: #4aa814;
  font-size: 12px;
}

@media (max-width: 767px) {
  .webshop-products-small .productsgrid__product {
    border: 1px solid #eee;
    margin-bottom: -1px !important;
    margin-right: -1px;
  }
}
@media (max-width: 767px) {
  .webshop-products-small .productsgrid__product .productitem {
    padding: 10px 5px;
  }
}

.categoryitem {
  font: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  border: none;
  padding: 15px;
}
.categoryitem:hover, .categoryitem:focus {
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 6px 30px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 6px 30px rgba(0, 0, 0, 0.3);
  text-decoration: none;
  color: inherit;
}
.categoryitem .categoryitem__image {
  text-align: center;
  margin-bottom: 10px;
  min-height: 200px;
}
.categoryitem .card-body {
  padding: 1rem;
}
.categoryitem .card-footer {
  position: relative;
  border-top: 0;
  background-color: rgba(0, 0, 0, 0.03);
}
.categoryitem__title {
  font-size: 24px;
  padding-bottom: 5px;
}
.categoryitem .btn {
  font-size: 16px;
}