@charset "UTF-8";
.bg-primary {
  background-color: #bd022a !important;
}

html.webshop--isproductpage main.main--margintop {
  margin-top: 0;
}
@media (max-width: 565px) {
  html.webshop--isproductpage .usps__schippers {
    padding: 0;
    margin-bottom: 10px;
  }
}
html.webshop--isproductpage .slick-next {
  right: auto;
  z-index: 1;
  position: inherit;
  margin: 0 auto;
  margin-top: 20px;
}
html.webshop--isproductpage .slick-prev {
  left: auto;
  z-index: 1;
  position: inherit;
  margin: 0 auto;
}
html.webshop--isproductpage .slick-prev:before {
  font-family: "Line Awesome Free";
  font-weight: 900;
  content: "\f0aa";
  color: #e7bd62;
}
html.webshop--isproductpage .slick-next:before {
  font-family: "Line Awesome Free";
  font-weight: 900;
  content: "\f0ab";
  color: #e7bd62;
}
html.webshop--isproductpage .tab-section:before {
  display: block;
  content: " ";
  height: 150px; /* Give height of your fixed element */
  margin-top: -150px; /* Give negative margin of your fixed element */
  visibility: hidden;
}
html.webshop--isproductpage .product_social_sharing {
  text-align: right;
  font-size: 24px;
}
html.webshop--isproductpage .product_social_sharing a {
  color: #000000;
}
html.webshop--isproductpage .product_social_sharing label {
  cursor: pointer;
}
html.webshop--isproductpage .product_social_sharing i {
  margin-right: 20px;
  margin-bottom: 10px;
}
html.webshop--isproductpage .product__social a {
  display: flex;
}
html.webshop--isproductpage .product__social a i {
  color: #000;
  font-size: 28px;
}
html.webshop--isproductpage .product__options {
  border-bottom: 1px solid #ddd;
}
html.webshop--isproductpage .product__options .webshop-product__title {
  font-size: 36px;
  margin-bottom: 0;
}
@media (max-width: 465px) {
  html.webshop--isproductpage .product__options .webshop-product__title {
    font-size: 26px;
    margin-bottom: 10px;
  }
}
html.webshop--isproductpage .product__options .webshop-product__subtitle {
  font-size: 21px;
  font-weight: 300;
}
html.webshop--isproductpage .product__options .card {
  background-color: #eee;
  border: 0 solid rgba(0, 0, 0, 0.2);
}
html.webshop--isproductpage .product__options .card .card-body {
  padding: 30px;
}
@media (max-width: 992px) {
  html.webshop--isproductpage .product__options .card .card-body {
    padding: 15px;
  }
}
html.webshop--isproductpage .product__options .card .card-body .niet-leverbaar,
html.webshop--isproductpage .product__options .card .card-body .nicht-vorrättig {
  color: #dc3545 !important;
}
html.webshop--isproductpage .product__options .card .card-body .checkmarksquare {
  margin-bottom: 0;
}
html.webshop--isproductpage .product__options .card .card-footer {
  background-color: #eee;
}
@media (max-width: 992px) {
  html.webshop--isproductpage .product__options .card .card-footer .btn {
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }
}
html.webshop--isproductpage .product__options .readmore__prod {
  text-decoration: underline;
  margin-bottom: 20px;
  display: block;
  margin-top: 10px;
}
html.webshop--isproductpage .product__options .webshop-product__optiontitle {
  float: left;
  width: 100px;
  font-weight: 400;
  margin-bottom: 0;
}
html.webshop--isproductpage .product__options .webshop-product__priceblock {
  margin-top: 10px;
  text-align: right;
}
html.webshop--isproductpage .product__options .webshop-product__listprice {
  font-size: 19px;
  font-weight: 400;
  color: #999;
  position: relative;
}
html.webshop--isproductpage .product__options .webshop-product__listprice:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0px;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 1px solid #999;
  border-radius: 0;
  transform: rotate(-11deg);
}
html.webshop--isproductpage .product__options .webshop-product__price {
  font-size: 32px;
  font-weight: 600;
  color: #000;
}
html.webshop--isproductpage .product__options .product__discountslogan {
  margin-top: 15px;
  color: #75b325;
  font-weight: 700;
}
html.webshop--isproductpage .product__options .product_promoimage > img {
  max-width: 100%;
  height: auto;
}
html.webshop--isproductpage .product__options .webshop-product__extraoptions {
  margin-top: 20px;
}
html.webshop--isproductpage .product__options .webshop-product__extraoptions p {
  margin-bottom: 10px;
  font-size: 18px;
}
html.webshop--isproductpage .product__info .nav-link {
  color: #000;
}
html.webshop--isproductpage .product__info .tab-section {
  margin-bottom: 50px;
}
html.webshop--isproductpage .product__info .nav-pills {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
html.webshop--isproductpage .product__info .nav-pills .nav-item.active {
  border-bottom: 4px solid #000;
}
html.webshop--isproductpage .product__info .nav-pills .nav-link {
  padding: 1rem;
}
html.webshop--isproductpage #product_tab2 {
  border-top: 1px solid #bfbfbf;
  padding-top: 40px;
}
html.webshop--isproductpage .product__related {
  border-top: 1px solid #bfbfbf;
  padding-top: 40px;
}
html.webshop--isproductpage .product__related h2 {
  font-size: 21px;
}
html.webshop--isproductpage .webshop-product__stockinfo {
  color: #00b900;
  text-align: right;
  margin-top: 10px;
  font-weight: 600;
}
html.webshop--isproductpage .webshop-product__stockinfo span {
  font-weight: 400;
}
html.webshop--isproductpage .product__images__grid {
  margin-top: 10px;
  margin-bottom: 10px;
}
html.webshop--isproductpage .product__images__grid .product__image {
  cursor: pointer;
}
html.webshop--isproductpage .slick-track {
  margin-left: 0;
  margin-right: auto;
}
@media (max-width: 577px) {
  html.webshop--isproductpage .product__images {
    margin: 20px 0;
  }
  html.webshop--isproductpage .product__images__preview img {
    max-height: 250px;
  }
  html.webshop--isproductpage .product__images .slick-next,
  html.webshop--isproductpage .product__images .slick-prev {
    top: 100%;
    margin-top: 15px;
    z-index: 1;
  }
  html.webshop--isproductpage .product__images .slick-next {
    right: 0px;
  }
  html.webshop--isproductpage .product__images .slick-prev {
    left: 0px;
  }
}
html.webshop--isproductpage .table-sm .spectablename,
html.webshop--isproductpage .table-sm .spectablevalue {
  padding: 0.4rem;
}
html.webshop--isproductpage .table .spectablename,
html.webshop--isproductpage .table .spectablevalue {
  border-top: 0;
}
html.webshop--isproductpage .spectablename {
  font-weight: 600;
  min-width: 145px;
}
html.webshop--isproductpage .product__image img {
  max-height: 100px;
  width: auto;
}
html.webshop--isproductpage .slick-vertical .slick-slide {
  opacity: 0.5;
}
html.webshop--isproductpage .slick-vertical .slick-slide.slick-current {
  opacity: 1;
}
html.webshop--isproductpage .slick-next:before, html.webshop--isproductpage .slick-prev:before {
  color: #000000;
}
html.webshop--isproductpage .product__advise {
  margin: 50px 0;
  background: url("../../web/img/bankstel-schippers-lifestyle-advies.jpg") top left no-repeat #000;
}
html.webshop--isproductpage .product__advise .row {
  min-height: 484px;
}
html.webshop--isproductpage .product__advise .row h2 {
  color: #fff;
  font-size: 46px;
  position: relative;
  display: inline-block;
  margin-bottom: 30px;
}
html.webshop--isproductpage .product__advise .row h2:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin: 10px auto;
  width: 40%;
  height: 3px;
  background: #fff;
}
html.webshop--isproductpage .product__advise .row p {
  color: #fff;
  font-size: 26px;
  margin-bottom: 30px;
}
html.webshop--isproductpage .product__tags {
  margin-top: 20px;
  margin-bottom: 20px;
}
html.webshop--isproductpage .product__tag {
  background: transparent;
  padding: 10px 15px;
  display: inline-block;
  margin: 2px 5px 5px 2px;
  color: #bbb;
  border-radius: 3px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out 0s;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-weight: 400;
  text-decoration: none;
}
html.webshop--isproductpage .product__tag:hover {
  background: #fff;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: inherit;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}
html.webshop--isproductpage .product__related {
  margin-top: 15px;
}
@media (min-width: 480px) and (max-width: 979px) {
  html.webshop--isproductpage .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  html.webshop--isproductpage .nav-link {
    display: block;
    padding: 0.5rem;
  }
  html.webshop--isproductpage .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
  html.webshop--isproductpage .product__options {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
  html.webshop--isproductpage .product__options {
    position: -webkit-sticky;
    position: sticky;
    top: 145px;
  }
}
@media (min-width: 320px) and (max-width: 979px) {
  html.webshop--isproductpage .productitem .btn {
    visibility: visible;
    opacity: 1;
    left: 0;
    right: auto;
  }
}

/*
  Hide <select> once the replacement .dd-container is live
*/
.dd-container + select {
  display: none;
}

.dd-selected {
  font-weight: normal;
  position: relative;
}

.dd-container {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  top: -5px;
}

.dd-selected-text,
.webshop-product__optiontitle {
  font-weight: 400;
  margin-bottom: 0;
}

/* Large desktop */
@media (min-width: 1300px) {
  #image-preview {
    position: absolute;
    z-index: 2001;
    border: 5px solid #fff;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
    -moz-box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
    box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
  }
  #image-preview img {
    max-width: 400px;
  }
}
/* Desktop */
@media (min-width: 979px) and (max-width: 1300px) {
  #image-preview {
    position: absolute;
    z-index: 2001;
    border: 5px solid #fff;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
    -moz-box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
    box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
  }
  #image-preview img {
    max-width: 400px;
  }
}
@media (max-width: 992px) {
  .webshop--isproductpage .product__images {
    margin-top: 1.5rem;
    overflow: hidden;
    background-color: #fff;
  }
  .webshop--isproductpage .product__options {
    border: none;
    padding: 0;
    box-shadow: none !important;
  }
  .webshop--isproductpage .product__options .dd-container {
    margin-bottom: 0;
  }
  .webshop--isproductpage .product__options .dd-select {
    border-radius: 2px;
    border: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }
}
@media (min-width: 480px) and (max-width: 979px) {
  .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
}
input.sl__favorite {
  position: absolute !important;
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
}
input.sl__favorite + label {
  margin: 0;
  font-size: 21px;
}
input.sl__favorite:checked + label > .sl--hide-if-favorite, input.sl__favorite:not(:checked) + label > .sl--show-if-favorite {
  display: none;
}