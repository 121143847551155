.bg-primary {
  background-color: #bd022a !important;
}

html.webshop--ishomepage body {
  background-color: #fff;
}
html.webshop--ishomepage body main {
  background-color: #fff;
  padding: 0;
  box-shadow: none !important;
}
html.webshop--ishomepage h1 {
  font-weight: 300;
  font-size: 28px;
}
html.webshop--ishomepage h1 b {
  font-weight: 600;
  font-size: 36px;
}
html.webshop--ishomepage .webshop-products--ordering-container {
  display: none;
}