.bg-primary {
  background-color: #bd022a !important;
}

html.webshop--ishomepage header.stickynav .topnav {
  margin-top: 9px;
}

@media (min-width: 992px) {
  .scroll-down #header,
  .scroll-up #header {
    transform: none;
  }
  .scroll-down #header .topbar,
  .scroll-up #header .topbar {
    display: none;
  }
  .scroll-up #header,
  .scroll-down #header {
    position: sticky;
    top: 0;
    z-index: 1020;
    background-color: #000;
  }
  .scroll-up #header .toplevelmenu__container,
  .scroll-down #header .toplevelmenu__container {
    margin: 0 auto;
  }
}
@media (max-width: 992px) {
  .scroll-down #header {
    transform: translate3d(0, -100%, 0);
  }
  .scroll-up #header {
    transform: none;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .scroll-up #header #header {
    background-color: #fff;
  }
}
header {
  position: relative;
  background-color: #fff;
  transition: 0.2s;
  /* Dim the screen on hover of menu item and focus on dropdown  */
}
header:after {
  clear: both;
  display: block;
  content: "";
}
header .topleftpanel .logo {
  display: inline-block;
  margin: 10px 0;
  transition: 0.2s;
}
header .topleftpanel .logo > img {
  height: auto;
  width: auto;
  width: 160px;
}
header .topleftpanel .webshop__promotekst {
  background-color: #bd022a;
  color: #fff;
  display: inline-block;
  padding: 7px 10px 4px 10px;
  margin-left: 10px;
  margin-top: 5px;
  position: relative;
  top: 3px;
  text-align: center;
  line-height: 20px;
}
header .topleftpanel .webshop__promotekst p {
  margin-bottom: 0;
  color: #fff;
}
header .topleftpanel .webshop__promotekst p b {
  display: block;
}
@media (max-width: 1024px) {
  header .topleftpanel .webshop__promotekst p {
    font-size: 12px;
    line-height: 15px;
  }
}
@media (max-width: 420px) {
  header .topleftpanel .webshop__promotekst {
    padding: 7px 5px 4px;
  }
  header .topleftpanel .webshop__promotekst p {
    font-size: 12px;
    line-height: 15px;
  }
}
header .topnav {
  margin-bottom: 0;
  float: right;
}
header .topnav a {
  padding: 0 0.5rem;
  color: #58595b;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  header .toprightpanel {
    padding: 15px 0 10px 0;
  }
}
header .toprightpanel .topright-link {
  margin-right: 5px;
}
header .toprightpanel i {
  color: #000;
  font-size: 30px;
  position: relative;
  top: 5px;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  header .toprightpanel i {
    font-size: 24px;
    position: relative;
    top: 7px;
    right: 5px;
  }
}
header .toprightpanel a {
  color: #58595b;
  font-size: 13px;
  text-transform: uppercase;
  display: block;
}
header .toprightpanel .list-inline-item {
  position: relative;
}
header .toprightpanel .badge {
  background: #bd022a;
  position: absolute;
  margin-left: -10px;
  top: -8px;
  right: 25px;
  font-size: 12px;
  line-height: 12px;
  padding: 4px 4px 4px 4px;
  min-width: 20px;
  font-family: arial, helvetica, sans-serif;
}
header .topmidpanel #topsearchform {
  margin-top: 20px;
  white-space: nowrap;
  transition: 0.2s;
}
@media (max-width: 767px) {
  header .topmidpanel #topsearchform {
    margin-top: 0;
  }
}
header .topmidpanel #topsearchform input {
  border-left: 0px solid #bfbfbf;
  border-top: 0px solid #bfbfbf;
  border-right: 0px solid #bfbfbf;
  outline: none;
  -webkit-appearance: none;
}
header .topmidpanel #topsearchform input::placeholder {
  color: #999;
  font-weight: 300;
  font-size: 16px;
}
header .topmidpanel #topsearchform button {
  border-right: 0px solid #bfbfbf;
  border-top: 0px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
  color: #000000;
}
header .topmidpanel #topsearchform button i {
  font-size: 21px;
}
@media (max-width: 1920px) {
  header.stickynav {
    left: 0;
  }
}
@media (max-width: 767px) {
  header .logo > img {
    max-width: 250px;
  }
  header.stickynav {
    position: sticky;
    top: 0;
    z-index: 3;
  }
  header.stickynav .logo img {
    max-width: 250px;
  }
}
@media (max-width: 576px) {
  header #navbar-wrapper {
    margin: 10px 0;
  }
  header .logo > img {
    max-width: 135px;
  }
  header.stickynav .logo img {
    max-width: 135px;
  }
  header .list-inline-item:first-child {
    margin-right: 0;
  }
}
header .navbar-dark {
  clear: both;
  background-color: #000;
}
header .navbar {
  padding: 0;
  position: relative;
  min-height: 43px;
}
header .navbar .basket {
  margin-right: 18px;
}
@media (min-width: 576px) {
  header .navbar .basket {
    display: none;
  }
}
header .navbar .badge {
  background: #bd022a;
  position: absolute;
  margin-left: -10px;
  top: 4px;
  right: 4px;
  font-size: 12px;
  line-height: 12px;
  padding: 4px 4px 4px 4px;
  min-width: 20px;
  font-family: arial, helvetica, sans-serif;
}
header .navbar-brand {
  font-size: 1rem;
}
header .navbar-dark .navbar-toggler {
  border-color: rgba(0, 0, 0, 0);
  color: #fff;
}
header .navbar-dark .navbar-toggler-icon {
  background-image: url(../web/img/toggler-icon.svg);
  width: 16px;
  height: 12px;
  position: relative;
  margin-right: 5px;
  top: -1px;
}
header .navbar-dark .navbar-toggler-txt {
  font-size: 16px;
}
header #navbar-wrapper {
  clear: both;
}
@media only screen and (min-width: 320px) and (max-width: 565px) {
  header #navbar-wrapper .container {
    padding-right: 5px;
    padding-left: 5px;
  }
}
header #mainnav {
  width: 100%;
  max-height: 42px;
}
header #mainnav .toplevelmenu__container .nav-link {
  color: #fff;
}
header #mainnav .toplevelmenu__container .nav-link i {
  color: #000000;
}
header #mainnav .toplevelmenu__container .toplevelmenu {
  margin: 0 auto;
  padding: 0;
  position: relative;
  z-index: 10;
}
header #mainnav .toplevelmenu__container .toplevelmenu__item.dropdown:hover > .dropdown-menu {
  display: block;
}
header #mainnav .toplevelmenu__container .toplevelmenu .dropdown-toggle::after {
  content: "\f107";
  font-family: "Line Awesome Free";
  font-weight: 900;
  border: 0;
  font-size: 14px;
  position: relative;
  top: 4px;
}
header #mainnav .toplevelmenu__container li {
  display: inline-block;
}
header #mainnav .toplevelmenu__container li.bg-primary {
  position: relative;
  top: -2px;
  height: 43px;
}
header #mainnav .toplevelmenu__container li:hover li a:hover {
  color: #fff;
  text-decoration: underline;
}
header #mainnav .dropdown-menu {
  min-width: 500px;
  width: max-content;
  padding: 2rem;
  margin: 0;
  background-color: #000000;
  border-radius: 0;
  border-top: 1px solid #444;
  min-height: 300px;
}
header #mainnav .dropdown-menu p.normal {
  display: none;
}
header #mainnav .dropdown-menu ul {
  display: block;
  float: left;
  padding-left: 0;
  margin-right: 20px;
  margin-bottom: 0;
}
header #mainnav .dropdown-menu ul li {
  display: block;
  color: #fff;
  position: relative;
  margin-left: 0;
  padding: 5px 10px;
}
header #mainnav .dropdown-menu ul li a {
  text-decoration: none;
  white-space: nowrap;
  color: #fff;
}
header #mainnav .dropdown-menu ul li strong, header #mainnav .dropdown-menu ul li b {
  font-size: 18px;
}
header .toprightpanel li.dropdown,
header .left-side-menu li.dropdown {
  text-align: right;
}
header .toprightpanel li.dropdown .dropdown-menu,
header .left-side-menu li.dropdown .dropdown-menu {
  margin-top: 0;
}
header .toprightpanel li.dropdown:hover > .dropdown-menu,
header .left-side-menu li.dropdown:hover > .dropdown-menu {
  display: block;
  width: max-content;
  min-width: 50px;
  border-radius: 0;
}
header .toprightpanel li.dropdown .dropdown-menu.show,
header .left-side-menu li.dropdown .dropdown-menu.show {
  display: block;
  width: max-content;
  min-width: 50px;
  border-radius: 0;
}
@media all and (min-width: 991px) {
  header .dropdown:after {
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* transition: opacity .15s ease;*/
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.2);
    pointer-events: none;
  }
  header .dropdown.show .nav-link,
  header .dropdown:hover .nav-link {
    position: relative;
    z-index: 12;
  }
  header .dropdown.show:after,
  header .dropdown:hover:after {
    z-index: 1;
    opacity: 1;
    visibility: visible;
    position: fixed;
  }
}

@media only screen and (min-width: 911px) and (max-width: 1200px) {
  header #mainnav .nav-link {
    padding: 0.5rem;
  }
}
@media only screen and (min-width: 767px) and (max-width: 991px) {
  header .toprightpanel .topright-link {
    margin-right: 0;
  }
  header .toprightpanel .list-inline-item {
    width: 80px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 991px) {
  .homenav {
    display: none;
  }
  #topsearchform .form-control {
    font-size: 13px;
    border-right: none;
  }
  #topsearchform .btn-link {
    color: #444;
    font-size: 18px;
    padding: 0px 8px;
    border: 1px solid #ced4da;
    border-left: none;
  }
}